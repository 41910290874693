<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <!-- Listado Lista Grupo Instituciones -->
        <v-data-table :headers="cabeceraListarGroup" :items="detalles" :loading="loading" :items-per-page="-1"
          :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <!-- Titulo y busqueda entrega -->
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">Integrantes CAE</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de entrega -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar" append-icon="update"
                @click:append="listar()" single-line hide-details></v-text-field>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="cargaData()" color="primary" fab x-small v-bind="attrs"
                    v-on="on"><v-icon>cloud_upload</v-icon></v-btn>
                </template>
                <span>Cargar Integrantes CAE</span>
              </v-tooltip>

              <!-- Modal crear data -->
              <v-dialog v-model="dialog" max-width="1200px" scrollable persistent>
                <v-card>
                  <v-form v-model="isValid">
                    <v-toolbar dark dense class="primary" flat>
                      <v-toolbar-title class="hidden-sm-and-down">Cargar Integrantes CAE desde Archivo Excel</v-toolbar-title>
                      <v-spacer />
                      <v-select dense :items="aanos" v-model="eano" label="Año" solo-inverted
                        :rules="[v => !!v || 'Año es requerido']" style="max-width: 100px;" class="mr-1" required
                        hide-details></v-select>
                      <v-select dense :items="anro_entregas" v-model="enro_entrega" label="Entrega" solo-inverted
                        :rules="[v => !!v || 'Item es requerido']" style="max-width: 130px;" class="mr-1" required
                        hide-details></v-select>
                      <v-spacer />
                      <v-btn icon @click="guardar()" :loading="desabilitar" :disabled="desabilitar || !isValid">
                        <v-icon>save</v-icon>
                      </v-btn>
                      <v-btn icon @click="closeData()">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-data-table :headers="cabeceraDetalleCae" :items="detalleCaes" :search="buscarCae"
                      :loading="loadingCD" dense>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>INTEGRANTES DE CAE</v-toolbar-title>
                          <v-divider class="mx-2" inset vertical></v-divider>
                          <input class="limpiarinput" type="file" @change="excelExportData"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                          <v-spacer />
                          <!-- Formulario busqueda-->
                          <v-text-field class="mr-1 text-xs-center" v-model.trim="buscarCae" append-icon="search" dense
                            label="Búsqueda" style="max-width: 300px;" single-line hide-details></v-text-field>
                          <!-- Fin Formulario Busqueda-->
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear data -->
            </v-toolbar>
            <!-- Fin Titulo y busqueda entrega -->
          </template>
          <!-- Opciones editar, eliminar -->
          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones editar, eliminar -->
        </v-data-table>
        <!--Fin Listado Lista Grupo Instituciones -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { read, utils } from "xlsx";
import moment from "moment";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      detalles: [],
      detalleCaes: [],

      dialog: false,

      cabeceraListarGroup: [
        { text: "AÑO", value: "ano", sortable: false },
        { text: "ENTREGA", value: "nro_entrega", sortable: false },
        { text: "TOTAL INTEG.", value: "cantidad", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false, width: 145 },
      ],

      cabeceraDetalleCae: [
        { text: "COD. MODULAR", value: "cod_modular", sortable: false, divider: true },
        { text: "DNI", value: "dni", sortable: false },
        { text: "AP. PATERNO", value: "apellido_paterno", sortable: false },
        { text: "AP. MATERNO", value: "apellido_materno", sortable: false },
        { text: "NOMBRES", value: "nombres", sortable: false },
        { text: "ROL", value: "rol", sortable: false },
        { text: "CARGO", value: "cargo", sortable: false },
        { text: "CELULAR", value: "celular", sortable: false },
        { text: "TELEFONO", value: "telefono", sortable: false },
      ],

      buscar: "",
      buscarCae: "",

      buscarLista: "",

      dialogActa: false,
      stringPdf: "",
      archivo: "",

      verInstituciones: 0,
      ano: "",
      nro_entrega: "",

      estado: "",
      itemnombre: "",
      loadingItem: false,


      fecha: "",

      loading: false,
      loadingMas: false,
      loadingCD: false,
      desabilitar: false,
      isValid: true,

      adModal: 0,
      adano: "",
      adnro_entrega: "",
      aditem: "",
      adestado: "",
      adtotal_iiee: "",

      // Filtros Masivo
      eano: moment().format("YYYY"),
      eitem: "",
      enro_entrega: "",
      aanos: [
        { text: '2023' },
        { text: '2024' },
      ],

      anro_entregas: [
        { text: "1" },
        { text: "2" },
        { text: "3" },
        { text: "4" },
        { text: "5" },
        { text: "6" },
        { text: "7" },
        { text: "8" },
        { text: "9" },
        { text: "10" },
      ],
      // Fin Filtros Masivo
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
    ...mapGetters(["esAdministrador", "esUsuario"]),

  },

  watch: {
    dialog(val) {
      val || this.closeData();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    convertToBase64() {
      //Read File
      var selectedFile = document.getElementById("inputFile").files;

      //console.log(event.target.files[0]);
      const fileCargado = selectedFile[0];
      // Verificamos si tenemos imagen en fileCargado
      if (fileCargado) {
        const reader = new FileReader();
        reader.readAsDataURL(fileCargado);
        reader.onload = (e) => {
          this.stringPdf = e.target.result; // Visualizamos en Base64
          this.archivo = this.stringPdf.replace("data:application/pdf;base64,", "");
          //console.log(this.archivo)
        };
      }
    },

    //#region LISTAR CARGAS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Nro_Entrega_Caes/Listar")
        .then(function (response) {
          me.detalles = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar integrantes CAE del Año: " + item.ano + " y Nro. Entrega: " + item.nro_entrega + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Nro_Entrega_Caes/EliminarGrupo/" + item.ano + "/" + item.nro_entrega)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },
    //#endregion

    //#region Nro Entrega Cae
    guardar() {
      let me = this;

      if (me.detalleCaes.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese CAES." });
      }


      me.desabilitar = true;

      axios.post("api/Nro_Entrega_Caes/Crear", {
        ano: me.eano,
        nro_entrega: me.enro_entrega,
        item: me.eitem,

        caedetalles: me.detalleCaes,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.closeData();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
          me.closeData();
          me.limpiar();
          me.listar();
        });
    },

    excelExportData(event) {
      let me = this;
      me.detalleCaes = [];
      me.loadingCD = true;
      me.desabilitar = true;

      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = read(fileData, { type: 'binary' });
        var rowObj = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        me.detalleCaes = rowObj
        //Reestablecemos valores
        me.loadingCD = false;
        me.desabilitar = false;
        document.getElementsByClassName("limpiarinput")[0].value = "";
        me.snackBar({ cl: "warning", msg: "El archivo Excel se cargó correctamente." });
      };
      reader.readAsBinaryString(input.files[0]);
    },

    cargaData() {
      this.dialog = true;
    },

    closeData() {
      this.dialog = false;
      document.getElementsByClassName("limpiarinput")[0].value = "";
      this.limpiar();
      this.listar();
    },
    //#endregion

    limpiar() {
      this.detalles = [];
      this.eitem = "";
      this.enro_entrega = "";

      this.buscarCae = "";
      this.detalleCaes = [];
    },

  },

};
</script>
