<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado de establecimiento -->
        <v-data-table :loading="loading" :headers="headers" :items="establecimientos" :items-per-page="15"
          :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">ESTABLECIMIENTOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscar" append-icon="search" dense label="Búsqueda"
                single-line hide-details></v-text-field>
              <v-spacer />
              <v-dialog v-model="dialog" max-width="400px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="9">
                          <v-text-field dense v-model.trim="nombre" label="Nombre"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 30) || 'Nombre debe tener entre 3 a 30 caracteres', Rules.unespacio]"
                            maxlength="30" prepend-inner-icon="store" v-uppercase clearable hide-details></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field dense v-model.trim="codigo_sunat" label="Cód. Sunat"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 4) || 'Nombre debe tener 4 caracteres']"
                            maxlength="4" @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="direccion" label="Direccion"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 200) || 'Dirección debe tener entre 3 a 60 caracteres', Rules.unespacio]"
                            maxlength="200" prepend-inner-icon="place" v-uppercase clearable hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                            v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                            prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="telefono" label="Telefono"
                            :rules="[v => !!v || 'Requerido', v => v.length <= 40 || 'El Telefono no debe tener mas de 40 caracteres', Rules.unespacio]"
                            maxlength="40" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="email" label="Email"
                            :rules="[v => /.+@.+\..+/.test(v) || 'El email debe ser válido', Rules.sinespacio]"
                            maxlength="50" prepend-inner-icon="mail" v-lowercase clearable hide-details></v-text-field>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close" small>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid" small>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn x-small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- Fin Listado de establecimiento -->
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      establecimientos: [],
      dialog: false,
      headers: [
        { text: "CdSc", value: "idestablecimiento", sortable: false },
        { text: "ESTABLECIMIENTO", value: "nombre", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "TELEFONO", value: "telefono", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],
      loading: false,

      buscar: "",
      buscar_ubigeo: "",
      editedIndex: -1,
      idestablecimiento: "",
      idubigeo: "",
      ubigeos: [],
      nombre: "",
      codigo_sunat: "",
      direccion: "",
      telefono: "",
      email: "",

      desabilitar: false,

      isValid: true,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Establecimiento" : "Actualizar Establecimiento";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar sucursal */
    listar() {
      let me = this;

      me.loading = true;
      axios.get("api/Establecimientos/Listar")
        .then(function (response) {
          //console.log(response);
          me.establecimientos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    // Seleccionar ubigeo por busqueda
    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == ""
                ? "-"
                : me.buscar_ubigeo == null
                  ? "-"
                  : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    /* Editar y guardar sucursal */
    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        // Editar
        axios.put("api/Establecimientos/Actualizar", {
          idestablecimiento: me.idestablecimiento,
          idubigeo: me.idubigeo,
          nombre: me.nombre,
          codigo_sunat: me.codigo_sunat,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        // Guardar
        axios.post("api/Establecimientos/Crear", {
          idubigeo: me.idubigeo,
          nombre: me.nombre,
          codigo_sunat: me.codigo_sunat,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    /* Eliminar sucursal */
    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar el establecimiento" + item.nombre + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Establecimientos/Eliminar/" + item.idestablecimiento)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    /* Editar sucursal item */
    editItem(item) {
      this.idestablecimiento = item.idestablecimiento;
      this.idubigeo = item.idubigeo;
      this.selectUbigeo(item.idubigeo);
      this.nombre = item.nombre;
      this.codigo_sunat = item.codigo_sunat;
      this.direccion = item.direccion;
      this.telefono = item.telefono;
      this.email = item.email;

      this.editedIndex = 1;
      this.dialog = true;
    },

    /* Cerrar ventana sucursal */
    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idestablecimiento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.codigo_sunat = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";

      this.editedIndex = -1;
    },
  },
};
</script>