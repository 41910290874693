import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1F618D',
        secondary: '#F0F0F0',
        accent: '#FF8C00',
        error: '#E53935',
        info: '#4FB3BF',
        success: '#388E3C',
        warning: '#FFC107',
        print: '#1D628B',
        more: '#8E135A'
      },
      dark: {
        primary: '#2980B9',
        secondary: '#333333',
        accent: '#FF8F00',
        error: '#FF5252',
        info: '#4DD0E1',
        success: '#81C784',
        warning: '#FFD54F',
        print: '#4392F1',
        more: '#9C27B0'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },

  //theme: { dark: false },
});
