function roundOut(num, dec) {
    let entero = Math.round(Number(num + "e+" + dec));
    let redondear = Number(entero + "e-" + dec);
    return redondear;
}

function isNum(event) {
    if (!/\d/.test(event.key)) { return event.preventDefault(); }
}

function numDec(event, number, dec) {
    if (!/\d/.test(event.key) && (event.key !== "." || /\./.test(number))) { return event.preventDefault(); }
    if (dec == 2) { if (/\.\d{2}/.test(number)) return event.preventDefault(); }
    if (dec == 3) { if (/\.\d{3}/.test(number)) return event.preventDefault(); }
    if (dec == 4) { if (/\.\d{4}/.test(number)) return event.preventDefault(); }
}

function valDec(value) {
    value = parseFloat(value)
    if (isNaN(value)) {
        return 0
    } else {
        return value
    }
}

function valInt(value) {
    value = parseInt(value)
    if (isNaN(value)) {
        return 0
    } else {
        return value
    }
}

function formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
}

export default {
    roundOut,
    isNum,
    numDec,
    valDec,
    valInt,
    formatDate
}