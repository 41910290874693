<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <template v-if="esAdministrador || esUsuario">
          <v-row align="center" justify="center">
            <v-col cols="6" md="3" sm="3">
              <v-card class="mx-auto text-center" color="brown lighten-2" dark max-width="600">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <div class="text-lg-h6">{{ cantidadCPE }}</div>
                  </v-sheet>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn block text x-small @click="irGuiasRemision()">Guías de Remisión Emitidas</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex"; //Importamos para llamar a los elementos

export default {
  data() {
    return {
      // Variables total dia
      resumen_dia: [],
      ingresos: 0,
      egresos: 0,
      // variables total meses
      ventas: [],
      compras: [],
      productos: [],

      nombreMesesVenta: [],
      totalMesesVenta: [],
      nombreMesesCompra: [],
      totalMesesCompra: [],
      nombreMesesProducto: [],
      totalMesesProducto: [],

      cantidadCPE: 0,
      montoCPE: 0,
    };
  },
  computed: {
    ...mapState(["usuario"]), // Llamamos a state para usuario
    ...mapGetters(["esAdministrador", "esUsuario"]),
  },

  created() {

    this.comprobantesEmitidos();
  },

  methods: {

    irGuiasRemision() {
      this.$router.push({ name: "guiasremision" }).catch(() => { });
    },

    comprobantesEmitidos() {
      let me = this;
      var cantidadCPEArray = [];
      axios.get("api/Movimientos/totalCPE")
        .then(function (response) {
          cantidadCPEArray = response.data;
          for (var i = 0; i < cantidadCPEArray.length; i++) {
            me.cantidadCPE = me.cantidadCPE + cantidadCPEArray[i].cantidad;
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },


  },
};
</script>