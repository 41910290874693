<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>

        <!-- Listado Traslados -->
        <v-data-table :loading="loading" :headers="cabeceraListarEntregas" :items="entregas" :items-per-page="15" dense
          class="elevation-3">
          <template v-slot:top>
            <!-- Titulo y busqueda entrega -->
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">ENTREGAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de entrega -->
              <v-menu v-model="menuBuscar" :close-on-content-click="!menuBuscar" transition="scale-transition" offset-y
                max-width="520px">
                <template v-slot:activator="{ on }">
                  <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar" v-on="on"
                    append-icon="filter_list" @click:append="menuBuscar = true" single-line hide-details></v-text-field>
                </template>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3" sm="3">
                        <v-select dense :items="aanos" v-model="eano" label="Año" @change="listar()" required
                          hide-details></v-select>
                      </v-col>
                      <v-col cols="12" md="2" sm="2">
                        <v-select dense :items="anro_entregas" v-model="enro_entrega" label="Entrega"
                          @change="selectRuta(), eruta = '', listar()" required hide-details></v-select>
                      </v-col>
                      <v-col cols="12" md="5" sm="5">
                        <v-select dense :items="aitems.slice(1)" v-model="eitem"
                          @change="selectRuta(), eruta = '', listar()" label="Item" required hide-details></v-select>
                      </v-col>
                      <v-col cols="10" md="2" sm="2">
                        <v-select dense :items="arutas" v-model="eruta" @change="listar()" label="Ruta" required
                          hide-details></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates" no-title
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de entrega -->

            </v-toolbar>
            <!-- Fin Titulo y busqueda entrega -->

          </template>
          <!-- Opciones editar, eliminar pedido y realizar venta -->
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones editar, eliminar pedido y realizar venta -->
        </v-data-table>
        <!--Fin Listado Traslados -->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="800px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Entrega</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      entregas: [],
      detalles: [],

      cabeceraListarEntregas: [
        { text: "ID", value: "identrega", sortable: false },
        { text: "AÑO", value: "ano", sortable: false },
        { text: "E", value: "nro_entrega", sortable: false },
        { text: "ITEM", value: "item_nombre", sortable: false },
        { text: "R", value: "ruta", sortable: false },
        { text: "NUM. DOC.", value: "doc_persona", sortable: false },
        { text: "DESTINATARIO", value: "nombre_persona", sortable: false },
        { text: "INSTITUCIÓN", value: "nombre_iiee", sortable: false },
        { text: "FEC.CARGA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false, width: 10 },
      ],

      buscar: "",
      menuBuscar: false,

      loading: false,

      identrega: "",

      fecha: "",

      comprobanteModal: 0,

      // Filtros Masivo
      eano: moment().format("YYYY"),
      enro_entrega: "1",
      eitem: "1",
      eruta: "",
      aanos: [
        { text: '2023' },
        { text: '2024' },
      ],
      arutas: [{ text: "" }],
      aitems: [],
      anro_entregas: [
        { text: "1" },
        { text: "2" },
        { text: "3" },
        { text: "4" },
        { text: "5" },
        { text: "6" },
        { text: "7" },
        { text: "8" },
      ],
      // Fin Filtros Masivo

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
    ...mapGetters(["esAdministrador", "esUsuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(parseInt(me.detalles[i].cantidad == "" ? 0.0 : me.detalles[i].cantidad) * parseFloat(me.detalles[i].precio_unit_compra == "" ? 0.0 : me.detalles[i].precio_unit_compra), 2);
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
  },

  created() {
    this.listar();
    this.selectItem();
  },

  methods: {
    ...mapActions(["snackBar"]),

    selectRuta(val) {
      let me = this;
      me.arutas = [{ text: "" }];

      var rutasArray = [];

      axios.get("api/Entregas/SelectRuta/" + me.eano + "/" + me.enro_entrega + "/" + me.eitem)
        .then(function (response) {
          rutasArray = response.data;
          rutasArray.map(function (x) {
            me.arutas.push({
              text: x.rutas,
            });
          });
        })
        .catch(function (error) { });
    },

    selectItem() {
      let me = this;

      var item_entradasArray = [];
      axios.get("api/Item_Entregas/Select")
        .then(function (response) {
          item_entradasArray = response.data;
          item_entradasArray.map(function (x) {
            me.aitems.push({
              text: x.nombre,
              value: x.item,
            });
            me.selectRuta();
          });
        })
        .catch(function (error) { });
    },

    //#region LISTAR ENTREGAS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Entregas/ListarFiltroEntregas", {
        params: {
          buscar: me.buscar,
          ano: me.eano,
          ruta: me.eruta,
          nro_entrega: me.enro_entrega,
          item: me.eitem,
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.entregas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    //#endregion

    //#region PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE
    async listarDetalleEntregas(id) {
      let me = this;
      var detallesArray = [];

      const response = await axios.get("api/Entregas/ListarDetallesEntregas/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear
          detallesArray.map(function (x) {
            me.detalles.push({
              identrega: x.identrega,
              cod_producto: x.cod_producto,
              descripcion_detallada: x.descripcion_detallada,
              iddetalle_compra: x.iddetalle_compra,
              idunidad_medida: x.idunidad_medida,
              cantidad: x.cantidad,
              // Impresion
              nom_unidad_medida: x.nom_unidad_medida,
            });
          });

        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
    //#endregion

    //#region IMPRESIÓN
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Entrega E" + this.nro_entrega;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.identrega = item.identrega;
      this.ano = item.ano;
      this.nro_entrega = item.nro_entrega;
      this.item = item.item;
      this.ruta = item.ruta;
      this.item_nombre = item.item_nombre;
      this.fecha = item.fecha;

      this.idtipo_doc_destinatario = item.idtipo_doc_destinatario;
      this.doc_persona = item.doc_persona;
      this.nombre_persona = item.nombre_persona;
      this.nombre_iiee = item.nombre_iiee;
      this.nivel_educativo = item.nivel_educativo;
      this.cod_modular = item.cod_modular;
      this.idubigeo_partida = item.idubigeo_partida;
      this.direccion_partida = item.direccion_partida;

      this.idubigeo_llegada = item.idubigeo_llegada;
      this.direccion_llegada = item.direccion_llegada;
      this.centro_poblado = item.centro_poblado;
      this.idmodalidad_transporte = item.idmodalidad_transporte;
      this.idtipo_doc_ct = item.idtipo_doc_ct;
      this.doc_transportista = item.doc_transportista;
      this.doc_conductor = item.doc_conductor;
      this.placa = item.placa;
      this.peso_total = item.peso_total;
      // Otros
      this.nombre_emp_transp = item.nombre_emp_transp;
      this.nombres_conductor = item.nombres_conductor;
      this.apellidos_conductor = item.apellidos_conductor;
      this.licencia_conductor = item.licencia_conductor;
      this.tipo_vehiculo = item.tipo_vehiculo;
      this.nom_ubi_partida = item.nom_ubi_partida;
      this.nom_ubi_llegada = item.nom_ubi_llegada;

      this.crearPDF(item.identrega);
      this.comprobanteModal = 1;

    },


    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESION

    limpiar() {
      this.identrega = "";
      this.ano = "";
      this.nro_entrega = "";
      this.item = "";
      this.ruta = "";
      this.item_nombre = "";
      this.fecha = "";

      this.idtipo_doc_destinatario = "";
      this.doc_persona = "";
      this.nombre_persona = "";
      this.nombre_iiee = "";
      this.nivel_educativo = "";
      this.cod_modular = "";
      this.idubigeo_partida = "";
      this.direccion_partida = "";

      this.idubigeo_llegada = "";
      this.direccion_llegada = "";
      this.centro_poblado = "";
      this.idmodalidad_transporte = "";
      this.idtipo_doc_ct = "";
      this.doc_transportista = "";
      this.doc_conductor = "";
      this.nombres_conductor = "";
      this.apellidos_conductor = "";
      this.licencia_conductor = "";
      this.tipo_vehiculo = "";
      this.placa = "";
      this.nombre_emp_transp = "";
      this.peso_total = "";
      // Otros
      this.nom_ubi_partida = "";
      this.nom_ubi_llegada = "";

      this.detalles = [];

      this.stringPdf = "";
      this.blobPdf = "";
    },

    async crearPDF(ident) {

      // Carga contenido de gre - detalle_entrega
      await this.listarDetalleEntregas(ident)

      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;

      var columns = [
        { header: "Nro", dataKey: "item" },
        { header: "CÓD.", dataKey: "cod_producto" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion_detallada" },
        { header: "U/M", dataKey: "nom_unidad_medida" },
        { header: "CANT.", dataKey: "cantidad" },
      ];

      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          item: i + 1,
          cod_producto: this.detalles[i].cod_producto,
          descripcion_detallada: this.detalles[i].descripcion_detallada,
          idunidad_medida: this.detalles[i].idunidad_medida,
          cantidad: this.detalles[i].cantidad,
          nom_unidad_medida: this.detalles[i].nom_unidad_medida,
        });
      }

      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 36, 18);
      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 50, right: 70, top: 17 },
        headStyles: {
          cellPadding: 0.4,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.4,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
          halign: "center",
        },
        body: [
          {
            columna0:
              this.usuario.direccion
          },
          {
            columna0:
              this.usuario.distrito +
              " - " +
              this.usuario.provincia +
              " - " +
              this.usuario.departamento,
          },
          //{ columna0: "Email: " + this.usuario.correo },
          { columna0: "Atención Cliente: " + this.usuario.telefono },
        ],
        columns: [{ header: this.usuario.emp_comercial, dataKey: "columna0" }],
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 22, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.text("RUC " + this.usuario.ruc, 168, 22, "center");
      doc.text("AÑO: " + this.ano + " ENTREGA: " + this.nro_entrega, 168, 27, "center");
      doc.text(" ITEM: " + this.item_nombre + " RUTA: " + this.ruta, 168, 32, "center");
      doc.text("ID" + this.identrega, 168, 37, "center");

      var margin = { left: 15, right: 15 };
      var headStyles = {
        cellPadding: 0.3,
        font: "helvetica",
        fontStyle: "normal" | "bold",
        fontSize: 6.8,
        fillColor: [227, 228, 229],
        textColor: 0,
        lineColor: [227, 228, 229],
        lineWidth: 0.2,
      };
      var columnStyles = {
        columna0: { cellWidth: 90 },
        columna1: { cellWidth: 90 },
      };
      var bodyStyles = {
        cellPadding: 0.3,
        font: "helvetica",
        fontStyle: "normal",
        fontSize: 6.8,
        textColor: 0,
        lineColor: [227, 228, 229],
        lineWidth: 0.2,
      };

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 6,
        theme: "grid",
        margin,
        styles: {
          overflow: "ellipsize",
          cellWidth: "wrap",
          lineColor: [227, 228, 229],
        },
        // tableLineColor: 0,
        // tableLineWidth: 0.2,
        headStyles,
        bodyStyles,
        body: [
          { columna0: "Razón Social: " + this.nombre_persona },
          { columna0: "RUC: " + this.doc_persona },
        ],
        columns: [{ header: "DESTINATARIO", dataKey: "columna0" }],
      });

      // ENVIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1.5,
        theme: "grid",
        margin,
        styles: {
          overflow: "ellipsize",
          cellWidth: "wrap",
          lineColor: [227, 228, 229],
        },
        // tableLineColor: 0,
        // tableLineWidth: 0.2,
        headStyles,
        bodyStyles,
        body: [
          {
            columna0: "IIEE: " + this.nivel_educativo + " - " + this.nombre_iiee + "  COD. MODULAR: " + this.cod_modular,
          },
          {
            columna0: "Peso Bruto Total(KGM): " + this.peso_total,
          },
          {
            columna0: "P. Partida: " + this.direccion_partida + " " + this.nom_ubi_partida
          },
          {
            columna0: "P. Llegada: " + this.direccion_llegada + " " + this.centro_poblado + " " + this.nom_ubi_llegada
          },
        ],
        columns: [
          { header: "ENVIO", dataKey: "columna0" },
        ],
      });

      // TRANSPORTE PRIVADO
      if (this.nombres_conductor != "") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 1.5,
          theme: "grid",
          margin,
          styles: {
            overflow: "ellipsize",
            cellWidth: "wrap",
            lineColor: [227, 228, 229],
          },
          //tableLineColor: 0,
          //tableLineWidth: 0.2,
          headStyles,
          bodyStyles,
          columnStyles,
          body: [
            {
              columna0: "Conductor: " + this.nombres_conductor + " " + this.apellidos_conductor,
              columna1: "Conductor DNI: " + this.doc_conductor,
            },
            {
              columna0: "Licencia Conductor: " + this.licencia_conductor,
              columna1: "Placa del vehiculo: " + this.placa + " " + this.tipo_vehiculo,
            },
          ],
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
        });
      }
      else if (this.nombre_emp_transp != "") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 1.5,
          theme: "grid",
          margin,
          //tableLineColor: 0,
          //tableLineWidth: 0.2,
          headStyles,
          bodyStyles,
          columnStyles: {
            columna0: { cellWidth: 140 },
            columna1: { cellWidth: 40 },
          },
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
          body: [
            {
              columna0: "TRANSPORTISTA: " + this.nombre_emp_transp,
              columna1: "RUC: " + this.doc_transportista,
            },
          ],
        });
      }

      // DETALLE GUIA REMISION
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1.5,
        theme: "grid",
        columns,
        body,
        margin,
        headStyles,
        bodyStyles,
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          cod_producto: { cellWidth: 16, halign: "center" },
          descripcion_detallada: { cellWidth: "auto" },
          nom_unidad_medida: { cellWidth: 16, halign: "center" },
          cantidad: { cellWidth: 10, halign: "right" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(6);
      doc.text("Desarrollado por URPIFACT", centro, finalY + 5, "center");

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },

  mounted() { },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>