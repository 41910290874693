<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>

        <!-- Listado Lista Grupo Instituciones -->
        <v-data-table :headers="cabeceraListarGroup" :items="detalles" :loading="loading" :items-per-page="-1"
          :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <!-- Titulo y busqueda entrega -->
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">CARGAS MASIVAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de entrega -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar" append-icon="update"
                @click:append="listar()" single-line hide-details></v-text-field>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="cargaData()" color="primary" fab x-small v-bind="attrs"
                    v-on="on"><v-icon>cloud_upload</v-icon></v-btn>
                </template>
                <span>Cargar Datos</span>
              </v-tooltip>

              <!-- Modal crear data -->
              <v-dialog v-model="dialog" max-width="1200px" scrollable persistent>
                <v-card>
                  <v-form v-model="isValid">
                    <v-toolbar dark dense class="primary" flat>
                      <v-toolbar-title class="hidden-sm-and-down">Cargar Datos desde Archivo Excel</v-toolbar-title>
                      <v-spacer />
                      <v-select dense :items="aanos" v-model="eano" label="Año" solo-inverted
                        :rules="[v => !!v || 'Año es requerido']" style="max-width: 100px;" class="mr-1" required
                        hide-details></v-select>
                      <v-select dense :items="anro_entregas" v-model="enro_entrega" label="Entrega" solo-inverted
                        :rules="[v => !!v || 'Item es requerido']" style="max-width: 130px;" class="mr-1" required
                        hide-details></v-select>
                      <v-select dense :items="aitems" v-model="eitem" label="Item" solo-inverted
                        :rules="[v => !!v || 'Item es requerido']" style="max-width: 180px;" class="mr-1" required
                        hide-details></v-select>
                      <v-spacer />
                      <v-btn icon @click="guardar()" :loading="desabilitar" :disabled="desabilitar || !isValid">
                        <v-icon>save</v-icon>
                      </v-btn>
                      <v-btn icon @click="closeData()">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-data-table :headers="cabeceraCargaIiee" :items="cargaIes" :search="buscarIE" :loading="loadingCD"
                      dense>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>INSTITUCIONES</v-toolbar-title>
                          <v-divider class="mx-2" inset vertical></v-divider>
                          <input class="limpiarinput" type="file" @change="excelExportData"
                            accept=" application/vnd.ms-excel.sheet.macroEnabled.12" />
                          <v-spacer />
                          <!-- Formulario busqueda-->
                          <v-text-field class="mr-1 text-xs-center" v-model.trim="buscarIE" append-icon="search" dense
                            label="Búsqueda" style="max-width: 300px;" single-line hide-details></v-text-field>
                          <!-- Fin Formulario Busqueda-->
                        </v-toolbar>
                      </template>
                    </v-data-table>
                    <v-data-table :headers="cabeceraCargaDetIiee" :items="cargaIeDetalles" :search="buscarIEDet"
                      :loading="loadingCD" dense>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>DETALLE INSTITUCIONES</v-toolbar-title>
                          <v-divider class="mx-2" inset vertical></v-divider>
                          <v-spacer />
                          <!-- Formulario busqueda-->
                          <v-text-field class="mr-1 text-xs-center" v-model.trim="buscarIEDet" append-icon="search" dense
                            label="Búsqueda" style="max-width: 300px;" single-line hide-details></v-text-field>
                          <!-- Fin Formulario Busqueda-->
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear data -->

              <!-- Ver Instituciones-->
              <v-dialog v-model="verInstituciones" max-width="900px">
                <v-card>
                  <v-toolbar dark dense class="primary mb-1" flat>
                    <v-toolbar-title>Año: {{ ano }} Entrega: {{ nro_entrega }} Item: {{ itemnombre }}</v-toolbar-title>
                    <v-spacer />
                    <v-text-field dense solo-inverted v-model.trim="buscarLista" label="Búsqueda"
                      prepend-inner-icon="search" clearable hide-details></v-text-field>
                    <v-spacer />
                    <v-btn icon @click="ocultarInstituciones()">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-data-table :headers="cabeceraInstitucion" :items="instituciones" :search="buscarLista" dense>
                    <template v-slot:item.num="{ item }">
                      <td>{{ instituciones.indexOf(item) + 1 }}</td>
                    </template>
                    <!-- Opciones editar, eliminar -->
                    <template v-slot:item.nombre_iiee="{ item }">
                      {{ item.nivel_educativo + " - " + item.nombre_iiee }}
                    </template>
                    <template v-slot:item.opciones="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="info" icon small v-bind="attrs" v-on="on" @click="irGuiaRemision(item)"
                            :disabled="item.productos > 0 ? false : true"><v-icon>description</v-icon></v-btn>
                        </template>
                        <span>Generar Guía Remisión</span>
                      </v-tooltip>
                    </template>
                    <!-- Fin Opciones editar, eliminar -->
                  </v-data-table>
                </v-card>
              </v-dialog>
              <!-- Fin Ver Instituciones-->

              <!-- Modal carga masiva -->
              <v-dialog v-model="adModal" max-width="500" persistent>
                <v-card>
                  <v-card-title class="justify-center"> Entrega Masiva
                  </v-card-title>
                  <v-card-text>
                    <v-form v-model="isValidM">
                      ¡Se ingresarán {{ adtotal_iiee }} Instituciones y sus detalles a la Lista de Entregas para su
                      posterior envío a SUNAT!
                      <v-select dense :items="establecimientos" v-model="idestablecimiento" label="Dirección Partida"
                        :rules="[v => !!v || 'Requerido']" class="mt-8" required hide-details></v-select>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="error" @click="cerrarEntrega" :disabled="loadingMas" small>Cancelar</v-btn>
                    <v-btn color="success" @click="guardarEntrega" :loading="loadingMas"
                      :disabled="loadingMas || !isValidM" small>Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal carga masiva -->
              <!-- Modal Minute -->
              <v-dialog v-model="dialogActa" max-width="300" persistent>
                <v-card>
                  <v-card-title class="justify-center"> {{ formTitleFile }} </v-card-title>
                  <v-card-text>
                    <v-form v-model="isValidFile">
                      <!-- <v-file-input id="inputFile" @change="convertToBase64()" accept=".pdf"
                            :rules="[v => !!v || 'Requerido']" hide-input></v-file-input> -->

                      <v-file-input id="inputFile" ref="fileInput" @change="convertToBase64()" accept=".pdf"
                        :rules="[v => !!v || 'Requerido']"></v-file-input>

                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="downloadPdf()" v-if="editedIndexFile == 1" small>Descargar</v-btn>
                    <v-btn color="error" @click="closeActa" :disabled="loadingActa" small>Cancelar</v-btn>
                    <v-btn color="success" @click="saveActa" :loading="loadingActa"
                      :disabled="loadingActa || !isValidFile" small>Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal Minute -->
            </v-toolbar>
            <!-- Fin Titulo y busqueda entrega -->
          </template>
          <!-- Opciones editar, eliminar -->
          <template v-slot:item.opciones="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showActa(item)" color="more" icon x-small v-bind="attrs"
                  v-on="on"><v-icon>feed</v-icon></v-btn>
              </template>
              <span>Actas</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="mostrarInstituciones(item)" color="accent" icon x-small v-bind="attrs"
                  v-on="on"><v-icon>assignment</v-icon></v-btn>
              </template>
              <span>Detalle Instituciones</span>
            </v-tooltip>

            <template v-if="item.estado == 'Creado' || usuario.idusuario == '1'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="mostrarEntrega(item)" color="info" icon x-small v-bind="attrs" v-on="on"
                    :disabled="item.sn == 'SI' ? false : true"><v-icon>reply_all</v-icon></v-btn>
                </template>
                <span>Entrega Masiva</span>
              </v-tooltip>

              <v-btn x-small icon>
                <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones editar, eliminar -->
        </v-data-table>
        <!--Fin Listado Lista Grupo Instituciones -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { read, utils } from "xlsx";
import moment from "moment";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      detalles: [],
      cargaIes: [],
      cargaIeDetalles: [],
      instituciones: [],

      dialog: false,

      cabeceraListarGroup: [
        { text: "AÑO", value: "ano", sortable: false },
        { text: "ENTREGA", value: "nro_entrega", sortable: false },
        { text: "ITEM", value: "itemnombre", sortable: false },
        { text: "TOTAL IIEE", value: "total_iiee", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 145 },
      ],

      cabeceraCargaIiee: [
        { text: "#", value: "nro", sortable: false, width: 10, divider: true },
        { text: "COD. MODULAR", value: "cod_modular", sortable: false, divider: true },
        { text: "UBIGEO", value: "idubigeo", sortable: false },
        { text: "CENTRO POBLADO", value: "centro_poblado", sortable: false },
        { text: "IIEE", value: "nombre_iiee", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "NIVEL", value: "nivel_educativo", sortable: false },
        { text: "DOC. DESTINATARIO", value: "doc_persona", sortable: false },
        { text: "DOC. TRANSPORTISTA", value: "doc_transportista", sortable: false },
        { text: "DOC. CONDUCTOR", value: "doc_conductor", sortable: false },
        { text: "PLACA", value: "placa", sortable: false },
        { text: "RUTA", value: "ruta", sortable: false },
      ],

      cabeceraCargaDetIiee: [
        { text: "#", value: "nro", sortable: false, width: 10, divider: true },
        { text: "COD. MODULAR", value: "cod_modular", sortable: false, divider: true },
        { text: "COD. PRODUCTO", value: "cod_producto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descrip_producto", sortable: false },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "PRESENTACIÓN", value: "presentacion", sortable: false },
        { text: "L/KG", value: "medida", sortable: false },
        { text: "U/M", value: "idunidad_medida", sortable: false, divider: true },
        { text: "CANT.", value: "cantidad", sortable: false, divider: true },
        { text: "EMPAQUE", value: "empaque", sortable: false },
      ],

      cabeceraInstitucion: [
        { text: "", value: "num", sortable: false },
        { text: "C.MODULAR", value: "cod_modular", sortable: false },
        { text: "INSTITUCIÓN", value: "nombre_iiee", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "PRODUCTOS", value: "productos", sortable: false, align: "center" },
        { text: "CANTIDADES", value: "cantidades", sortable: false, align: "center" },
        { text: "PESO/KGM", value: "peso_total", sortable: false, align: "right" },
        { text: "OPC.", value: "opciones", sortable: false },
      ],

      buscar: "",
      buscarIE: "",
      buscarIEDet: "",

      buscarLista: "",

      dialogActa: false,
      stringPdf: "",
      archivo: "",

      verInstituciones: 0,
      ano: "",
      nro_entrega: "",
      item: "",
      estado: "",
      itemnombre: "",
      loadingItem: false,

      editedIndexFile: -1,
      loadingActa: false,
      isValidFile: true,

      fecha: "",

      loading: false,
      loadingMas: false,
      loadingCD: false,
      desabilitar: false,
      isValid: true,
      isValidM: true,

      adModal: 0,
      adano: "",
      adnro_entrega: "",
      aditem: "",
      adestado: "",
      adtotal_iiee: "",

      idestablecimiento: "",
      establecimientos: [],

      // Filtros Masivo
      eano: moment().format("YYYY"),
      eitem: "",
      enro_entrega: "",
      aanos: [
        { text: '2023' },
        { text: '2024' },
      ],
      aitems: [],
      anro_entregas: [
        { text: "1" },
        { text: "2" },
        { text: "3" },
        { text: "4" },
        { text: "5" },
        { text: "6" },
        { text: "7" },
        { text: "8" },
        { text: "9" },
        { text: "10" },
      ],
      // Fin Filtros Masivo
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
    ...mapGetters(["esAdministrador", "esUsuario"]),

    formTitleFile() {
      return this.editedIndexFile === -1 ? "Cargar Acta" : "Actualizar Acta";
    },
  },

  watch: {
    dialog(val) {
      val || this.closeData();
    },
  },

  created() {
    this.select();
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    convertToBase64() {
      //Read File
      var selectedFile = document.getElementById("inputFile").files;

      //console.log(event.target.files[0]);
      const fileCargado = selectedFile[0];
      // Verificamos si tenemos imagen en fileCargado
      if (fileCargado) {
        const reader = new FileReader();
        reader.readAsDataURL(fileCargado);
        reader.onload = (e) => {
          this.stringPdf = e.target.result; // Visualizamos en Base64
          this.archivo = this.stringPdf.replace("data:application/pdf;base64,", "");
          //console.log(this.archivo)
        };
      }
    },

    //#region LISTAR CARGAS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Cargas/ListarGrupoItem")
        .then(function (response) {
          me.detalles = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la carga de " + item.total_iiee + " Instituciones  Año: " + item.ano + " Entrega: " + item.nro_entrega + " Item: " + item.itemnombre + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Cargas/EliminarGroup/" + item.ano + "/" + item.nro_entrega + "/" + item.item + "/" + item.estado + "/" + item.sn)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },
    //#endregion

    //#region SElECT
    select() {
      let me = this;

      var item_entradasArray = [];
      axios.get("api/Item_Entregas/Select")
        .then(function (response) {
          item_entradasArray = response.data;
          item_entradasArray.map(function (x) {
            if (x.item) {
              me.aitems.push({
                text: x.nombre,
                value: x.item,
              });
            }
          });
        })
        .catch(function (error) { });

      var establecimientosArray = [];
      axios.get("api/Establecimientos/Select")
        .then(function (response) {
          establecimientosArray = response.data;
          establecimientosArray.map(function (x) {
            me.establecimientos.push({ text: x.nombre + " - " + x.direccion, value: x.idestablecimiento });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //#endregion SELECT

    //#region CARGA
    guardar() {
      let me = this;

      if (me.cargaIes.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese Instituciones." });
      }

      if (me.cargaIeDetalles.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese Detalle Instituciones." });
      }

      me.desabilitar = true;

      axios.post("api/Cargas/CrearMasivo", {
        ano: me.eano,
        nro_entrega: me.enro_entrega,
        item: me.eitem,

        cargaies: me.cargaIes,
        cargaiedetalles: me.cargaIeDetalles,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.closeData();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
          me.closeData();
          me.limpiar();
          me.listar();
        });
    },

    excelExportData(event) {
      let me = this;
      me.cargaIes = [];
      me.cargaIeDetalles = [];
      me.loadingCD = true;
      me.desabilitar = true;

      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = read(fileData, { type: 'binary' });
        var rowObj = utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]]);
        var rowObjDet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[3]]);
        me.cargaIes = rowObj
        me.cargaIeDetalles = rowObjDet
        //Reestablecemos valores
        me.loadingCD = false;
        me.desabilitar = false;
        document.getElementsByClassName("limpiarinput")[0].value = "";
        me.snackBar({ cl: "warning", msg: "El archivo Excel se exporto correctamente." });
      };
      reader.readAsBinaryString(input.files[0]);
    },

    cargaData() {
      this.dialog = true;
    },

    closeData() {
      this.dialog = false;
      document.getElementsByClassName("limpiarinput")[0].value = "";
      this.limpiar();
      this.listar();
    },
    //#endregion

    //#region ENTREGA
    mostrarEntrega(item) {
      this.adModal = 1;
      this.adano = item.ano;
      this.adnro_entrega = item.nro_entrega;
      this.aditem = item.item;
      this.adestado = item.estado;

      this.adtotal_iiee = item.total_iiee;
    },

    cerrarEntrega() {
      this.limpiarEntrega();
    },


    guardarEntrega() {
      let me = this;
      me.loadingMas = true;

      axios.put("api/Entregas/CargarEntregaMasiva/" + me.adano + "/" + me.adnro_entrega + "/" + me.aditem + "/" + me.adestado + "/" + me.idestablecimiento)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data }); // Respuesta de sunat
          me.limpiarEntrega();
          me.listar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.limpiarEntrega();
          me.listar();
        });
    },

    limpiarEntrega() {
      this.adModal = 0;
      this.adano = "";
      this.adnro_entrega = "";
      this.aditem = "";
      this.adestado = "";
      this.idestablecimiento = "";
      this.adtotal_iiee = "";

      this.loadingMas = false;
    },

    saveActa() {
      let me = this;
      me.loadingActa = true;

      if (this.editedIndexFile > -1) {
        axios.put("api/Item_Archivos/Actualizar", {
          iditem_archivo: me.iditem_archivo,
          ano: me.adano,
          nro_entrega: me.adnro_entrega,
          item: me.aditem,
          archivo: me.archivo,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.closeActa();
            me.loadingActa = false;
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else {
        axios.post("api/Item_Archivos/Crear", {
          ano: me.adano,
          nro_entrega: me.adnro_entrega,
          item: me.aditem,
          archivo: me.archivo,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.closeActa();
            me.loadingActa = false;
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    showActa(item) {
      let me = this;

      me.adano = item.ano;
      me.adnro_entrega = item.nro_entrega;
      me.aditem = item.item;

      axios.get("api/Item_Archivos/Mostrar/" + me.adano + "/" + me.adnro_entrega + "/" + me.aditem)
        .then(function (response) {
          var data = response.data;
          me.dialogActa = true;
          me.editedIndexFile = 1;
          // Cargamos datos
          me.iditem_archivo = data.iditem_archivo;
          me.archivo = data.archivo;
        })
        .catch(function (error) {
          me.dialogActa = true
          me.editedIndexFile = -1;
          //console.log(error);
        });

    },

    downloadPdf() {
      // Decodificar la cadena base64
      const decodedData = atob(this.archivo);

      // Convertir la cadena decodificada en un array de bytes
      const byteArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }

      // Crear el Blob y el enlace de descarga
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const fileName = "Acta.pdf";
      link.download = fileName;

      // Simular el clic en el enlace para iniciar la descarga
      link.click();
    },

    closeActa() {
      this.$refs.fileInput.reset();
      this.adano = "";
      this.adnro_entrega = "";
      this.aditem = "";
      this.archivo = "";
      this.stringPdf = "";
      this.editedIndexFile = -1;
      this.dialogActa = false;
      this.isValidFile = false;

    },
    //#endregion ENTREGA

    //#region INTITUCIONES
    irGuiaRemision(item) {
      this.$router.push({
        name: "guias_remision",
        params: {
          carga: true,
          cargaano: item.ano,
          carganro_entrega: item.nro_entrega,
          cargaitem: item.item,
          cargacodmodular: item.cod_modular,
          cargaruta: item.ruta,
          carganombreiiee: item.nombre_iiee,
          cargadireccion_llegada: item.direccion,
          cargacentropoblado: item.centro_poblado,
          carganiveleducativo: item.nivel_educativo,
          cargadocpersona: item.doc_persona,
          cargaidubigeo: item.idubigeo,
          cargapesototal: item.peso_total,
        },
      });
    },

    listarItem() {
      let me = this;
      me.loadingItem = true;
      axios.get("api/Cargas/ListarCargaxItem", {
        params: {
          ano: me.ano,
          nro_entrega: me.nro_entrega,
          item: me.item,
          estado: me.estado,
          sn: me.sn
        },
      })
        .then(function (response) {
          me.instituciones = response.data;
          me.loadingItem = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    mostrarInstituciones(item) {
      this.itemnombre = item.itemnombre;
      this.ano = item.ano;
      this.nro_entrega = item.nro_entrega;
      this.item = item.item;
      this.estado = item.estado;
      this.sn = item.sn;
      this.listarItem();
      this.verInstituciones = 1;
    },

    ocultarInstituciones() {
      this.itemnombre = "";
      this.ano = "";
      this.nro_entrega = "";
      this.item = "";
      this.buscarLista = "";
      this.instituciones = [];
      this.loadingItem = false;
      this.verInstituciones = 0;
    },
    //#endregion INTITUCIONES

    limpiar() {
      this.detalles = [];
      // Cargas
      //this.eano = "";
      this.eitem = "";
      this.enro_entrega = "";

      this.buscarIE = "";
      this.buscarIEDet = "";
      this.cargaIes = [];
      this.cargaIeDetalles = [];
    },

  },

};
</script>
