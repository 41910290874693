<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" v-if="estaActivo" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-if="esAdministrador || esUsuario">
          <v-list-item :to="{ name: 'dashboard' }">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Entregas -->
        <template v-if="esAdministrador || esUsuario">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>inventory</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Entregas</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'item_entrega' }">
              <v-list-item-content>
                <v-list-item-title>Items</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>category</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'nro_entrega_cae' }">
              <v-list-item-content>
                <v-list-item-title>Integrantes CAE</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'carga' }">
              <v-list-item-content>
                <v-list-item-title>Cargas Masivas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>move_down</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'entrega' }">
              <v-list-item-content>
                <v-list-item-title>Entregas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>sync_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Entregas -->

        <!-- Guías de Remisión -->
        <template v-if="esAdministrador || esUsuario">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>article</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Guía de Remisión</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'guias_remision' }">
              <v-list-item-content>
                <v-list-item-title>GRE Remitente</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>receipt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'persona' }">
              <v-list-item-content>
                <v-list-item-title>Destinatarios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>groups</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'conductor' }">
              <v-list-item-content>
                <v-list-item-title>Conductores</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>group</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'transportista' }">
              <v-list-item-content>
                <v-list-item-title>Transportistas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>local_shipping</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'vehiculo' }">
              <v-list-item-content>
                <v-list-item-title>Vehiculos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>emoji_transportation</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Guías de Remisión -->

        <!-- Mantenimiento -->
        <template v-if="esAdministrador">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>settings_suggest</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Mantenimiento</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'empresas' }">
              <v-list-item-content>
                <v-list-item-title>Empresa</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>home_work</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'establecimientos' }">
              <v-list-item-content>
                <v-list-item-title>Establecimientos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'usuarios' }">
              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>supervised_user_circle</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'roles' }">
              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>verified_user</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'unidad_medidas' }">
              <v-list-item-content>
                <v-list-item-title>Unidad Medida</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>compare_arrows</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Mantenimiento -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app :color="usuario.color ? $vuetify.theme.themes.light.primary = usuario.color : $vuetify.theme.themes.light.primary" dark :dense="estaActivo">
      <!-- Logo y titulo -->
      <v-toolbar-title style="width: 300px" class="ml-0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="estaActivo"></v-app-bar-nav-icon>
          <v-avatar tile size="35px" class="mx-1" v-if="!estaActivo" large item>
              <v-img :src="require('./assets/logo.png')" alt="Vuetify" />
          </v-avatar>
        <!-- <span v-if="estaActivo" class="font-weight-black">{{ usuario.empresa }}</span> -->
        <span v-if="estaActivo" class="font-weight-black">{{ usuario.nom_logo }}</span>
        <span v-else class="font-weight-black">UrpiFact</span>
      </v-toolbar-title>
      <!-- Fin Logo y titulo -->

      <v-spacer />

      <!-- Link -->
      <v-toolbar-items class="hidden-sm-and-down" v-if="!estaActivo">
        <v-btn v-for="item, i in menu" :key="i" :to="item.link" text>{{ item.name }}</v-btn>
      </v-toolbar-items>

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn class="hidden-md-and-up" v-if="!estaActivo" v-on="on" text fab>
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="item, i in menu" :key="i" :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Fin Link -->
      <!-- Notificaciones -->
      <v-menu bottom left v-model="menuNotifCpe" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="esAdministrador || esUsuario">
        <template v-slot:activator="{ on }">

          <v-btn icon v-on="on" @click="cargarNotifCpes()">
            <v-badge :content="notifcpes.length" :value="notifcpes.length" color="red" overlap>
              <v-icon @click="">notification_important</v-icon>
            </v-badge>
          </v-btn>

        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="error">warning</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>GRE REMITENTE NO ENVIADOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(notifcpes, i) in notifcpes" :key="i">
            <!-- <v-list-item-icon>
              <v-icon small color="amber">description</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title> {{ notifcpes.serie_comprobante }}-{{ notifcpes.num_comprobante }} | {{ notifcpes.fecha |
                formatearFecha }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title class="orange--text">{{ notifcpes.estado }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Fin Notificaciones -->

      <!-- oscuro -->
      <v-btn icon dark hide-details @click="$vuetify.theme.dark = !$vuetify.theme.dark" v-if="estaActivo">
        <v-icon>contrast</v-icon>
      </v-btn>
      <!-- Fin oscuro -->

      <!-- Cerrar sesion -->
      <v-menu bottom left v-model="menuSesion" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="estaActivo">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="35px" item>
              <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="40px" item>
                  <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                <v-list-item-subtitle> {{ usuario.rol }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.email_user }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.emp_comercial.substring(0, 21) + "..." }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon>card_membership</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="info--text">VCTO CERT: {{ usuario.vcto_certificado }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn small block color="error" @click="salir(), (menuSesion = false)">Cerrar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- Fin Cerrar sesion -->
    </v-app-bar>
    <!-- Contenido -->
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>
    <!-- Fin Contenido -->
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="snackbar.timeout"
      transition="slide-y-reverse-transition" right>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.message }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.active = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Fin Snackbar -->
    <!-- Pie de Pagina -->
    <v-footer padless v-if="!estaActivo">
      <v-row no-gutters class="primary text-center">
        <v-col cols="12">
          <div class="font-weight-regular text-h7 white--text mt-4">
            <a class="white--text" target="_blank" href="https://wa.me/+51930984158" style="text-decoration:none"><v-icon
                dark class="mb-1 mx-1">whatsapp</v-icon>+51930984158</a>
            <a class="white--text" target="_blank" href="https://www.facebook.com/urpisoft"
              style="text-decoration:none"><v-icon dark class="mb-1 mx-1">facebook</v-icon></a>
          </div>
          <div class="font-weight-regular text-h7 white--text">
            <v-icon dark class="mb-1 mx-1">location_on</v-icon>Jr. Los Pinos Mz. D Lt. 4, Urb. La Florida , Juliaca.
          </div>
          <div class="font-weight-regular text-h7 white--text mb-4">
            <v-icon dark class="mb-1 mx-1">email</v-icon>efrain@urpisoft.pe
          </div>
        </v-col>
      </v-row>
      <v-card-text class="primary darken-2 text-center white--text">Copyright © UrpiFact {{ new Date().getFullYear() }}
        Todos los derechos reservados. v2.1.16</v-card-text>
    </v-footer>
    <!-- Fin Pie de Pagina -->
  </v-app>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      headers: [
        { text: "NOMBRES", value: "persona" },
        { text: "IMPORTE", value: "importe" },
      ],
      drawer: null,
      menuSesion: false,
      menuNotifCpe: false,
      fecha_actual: moment().format("YYYY-MM-DD"),

      menu: [
        { link: "inicio", name: "Inicio" },
        { link: "soluciones", name: "Soluciones" },
        { link: "login", name: "Login" },
      ],
    };
  },

  computed: {
    ...mapState(["usuario", "avatar", "notifcpes", "snackbar"]),
    ...mapGetters([
      "estaActivo",
      "esAdministrador",
      "esUsuario",
    ]),
  },

  created() {
    this.autoLogin();
  },

  methods: {
    ...mapActions([
      "salir",
      "autoLogin",
      "cargarNotifCpes",
    ]),

    irPedidoProveedor() {
      this.$router.push({ name: "pedido_proveedores" }).catch(() => { });
    },
    irPedidoCliente() {
      this.$router.push({ name: "pedido_clientes" }).catch(() => { });
    },

  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>