<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado personas -->
        <v-data-table :loading="loading" :headers="headers" :items="personas" :items-per-page="15" dense
          class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">DESTINATARIOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Impresion personas -->
              <v-btn icon small color="print" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Impresion personas -->
              <v-spacer />
              <!-- busqueda -->
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscar" append-icon="search" dense label="Búsqueda"
                @keyup="listar()" single-line hide-details></v-text-field>
              <!-- Fin busqueda -->
              <v-spacer />
              <!-- Modal crear y modificar -->
              <v-dialog v-model="dialog" max-width="500px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="6" sm="4">
                          <v-select dense v-model="idtipo_documento" :items="tipo_documentos" label="Tipo Documento"
                            :rules="[v => !!v || 'Tipo documento requerido']" @change="doc_persona = ''"
                            :disabled="desabilitarDoc" hide-details>
                          </v-select>
                        </v-col>
                        <v-col cols="10" sm="5">
                          <v-text-field dense v-model.trim="doc_persona" label="Numero Documento"
                            :rules="[v => (!!v && v > 0) || 'Numero documento requerido', v => (idtipo_documento == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                            :maxlength="idtipo_documento == 1 ? 8 : 11"
                            :disabled="editedIndex == 1 ? desabilitarDoc : (idtipo_documento == 1 ? false : idtipo_documento == 6 ? false : true)"
                            @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="1">
                          <div class="text-center">
                            <v-btn x-small fab color="primary" class="white--text" :loading="desabilitarBuscar"
                              :disabled="(idtipo_documento == 1 && doc_persona.length == 8 ? false : idtipo_documento == 6 && doc_persona.length == 11 ? false : true) || desabilitarBuscar"
                              @click="extraerDocumento(doc_persona)">
                              <v-icon>search</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="nombre"
                            :label="idtipo_documento == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                            :rules="[v => !!v || 'Nombres requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombres debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                            maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                            v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                            prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="direccion" label="Direccion"
                            :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                            maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close" small>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear y modificar -->
            </v-toolbar>
          </template>

          <template v-slot:item.idtipo_documento="{ item }">
            {{ item.idtipo_documento == 1 ? "DNI" : "RUC" }}
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn x-small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- Fin Listado personas -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      personas: [],
      dialog: false,
      headers: [
        { text: "TIPO", value: "idtipo_documento", sortable: false },
        { text: "DOCUMENTO", value: "doc_persona", sortable: false },
        { text: "NOMBRES", value: "nombre", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false, width: 90 },
      ],
      buscar: "",
      buscar_ubigeo: "",

      editedIndex: -1,
      doc_persona: "",

      nombre: "",
      idtipo_documento: "",
      tipo_documentos: [
        { text: "DNI", value: 1 },
        { text: "RUC", value: 6 }
      ],
      doc_persona: "",
      idubigeo: "",
      ubigeos: [],
      direccion: "",

      desabilitar: false,
      desabilitarBuscar: false,
      desabilitarDoc: false,

      isValid: true,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["logo"]),

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Destinatario" : "Actualizar Destinatario";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Personas/ListarFiltro", {
        params: {
          buscar: me.buscar == "" ? null : me.buscar,
        },
      })
        .then(function (response) {
          //console.log(response);
          me.personas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == ""
                ? "-"
                : me.buscar_ubigeo == null
                  ? "-"
                  : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        axios.put("api/Personas/Actualizar", {
          doc_persona: me.doc_persona,
          idtipo_documento: me.idtipo_documento,
          idubigeo: me.idubigeo,
          nombre: me.nombre,
          direccion: me.direccion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        axios.post("api/Personas/Crear", {
          doc_persona: me.doc_persona,
          idtipo_documento: me.idtipo_documento,
          idubigeo: me.idubigeo,
          nombre: me.nombre,
          direccion: me.direccion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data.mensaje });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar el destinatario " + item.nombre + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Personas/Eliminar/" + item.doc_persona)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    editItem(item) {
      this.doc_persona = item.doc_persona;
      this.idtipo_documento = item.idtipo_documento;
      this.idubigeo = item.idubigeo;
      this.nombre = item.nombre;
      this.selectUbigeo(item.idubigeo);
      this.direccion = item.direccion;

      this.desabilitarDoc = true;
      this.editedIndex = 1;
      this.dialog = true;
    },

    extraerDocumento(doc_persona) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          doc_persona +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre = data.apellidoPaterno + " " + data.apellidoMaterno + " " + data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.doc_persona,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion = response.data.direccion == null ? "" : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.doc_persona = "";
      this.nombre = "";
      this.idtipo_documento = "";
      this.doc_persona = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.direccion = "";

      this.desabilitarDoc = false;
      this.editedIndex = -1;
    },

    crearPDF() {
      var columns = [
        { header: "TIPO", dataKey: "tipo" },
        { header: "DOCUMENTO", dataKey: "doc_persona" },
        { header: "NOMBRE", dataKey: "nombre" },
        { header: "DIRECCIÓN", dataKey: "direccion" },
      ];
      var body = [];

      this.personas.map(function (x) {
        body.push({
          tipo: x.idtipo_documento == 1 ? "DNI" : "RUC",
          doc_persona: x.doc_persona,
          nombre: x.nombre,
          direccion: x.direccion,
        });
      });

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO DE DESTINATARIOS", doc.internal.pageSize.width / 2, 45, { align: "center", });
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Pagina " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 820, { align: "center", });
        }
      };

      // Configurando hoja
      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          tipo: { cellWidth: 40 },
          doc_persona: { cellWidth: 80 },
          nombre: { cellWidth: "auto" },
          direccion: { cellWidth: 200 },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("Destinatarios.pdf");
    },
  },
};
</script>